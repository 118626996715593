<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class=" text-h2 font-weight-bold">Aguas México</h1>
        <p class="text-body-2">V 1.0.2</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="green--text text-h6 text-justify">Soy un desarrollador mexicano que piensa que la información es
        muy importante y aún más en un país como el nuestro, en el cual estamos sufriendo una crisis de agua muy
         fuerte.</h1>
        <h1 class="red--text text-h6 text-justify">Es por eso que he creado este sitio, en el cual, al dar click en el botón
          <a class="black--text" href="/tengoAgua">presas</a> podrás encontrar el nombre, la capacidad en hectómetros cuadrados, la cantidad actual en hectómetros cuadrados y el porcentaje en el que se encuentra cada presa del estado seleccionado.</h1>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col>
        <p class="text-h4 font-weight-bold">Preguntas frecuentes</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="text-h5 text-start">¿De dónde viene la información?</p>
        <p class="text-body-1 text-start">La información es recolectada del Sistema Nacional de Información del Agua
          Monitoreo de las Principales Presas de México de CONAGUA</p>
      </v-col>
      <v-col cols="12">
        <p class="text-h5 text-start">¿Qué tan actualizada está la información?</p>
        <p class="text-body-1 text-start">La información suele ser del día actual, pero en algunos casos CONAGUA no reporta información algunos días,
         el retraso de información no suele ser de más de 5 días</p>
      </v-col>
      <v-col cols="12">
        <p class="text-h5 text-start">¿Qué significa h/m3?</p>
        <p class="text-body-1 text-start">h/m3 es una medida que usa CONAGUA para medir la capacidad de las presas
         y significa hectómetro cubicos, cadad hectómetro cuadrado representa 1 millón de metros cubicos y cada metro cubico
         representa mil litros de agua. Por lo tanto 1 hectómetro cuadrado representa MIL MILLONES DE LITROS DE AGUA</p>
      </v-col>
      <v-col cols="12">
        <p class="text-h5 text-start">¿Cómo se mantiene el sitio?</p>
        <p class="text-body-1 text-start">Se utiliza publcidad para generar un ingreso que sea suficiente para los
        costos de mantenimiento del sitio, por favor desactiva los bloqueadores de anuncios para apoyar al sitio</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase'
export default {
  name: 'Home',
  mounted() {
    this.registerAnalytics()
  },
  methods:{
    registerAnalytics(){
      firebase.analytics().logEvent("Pantalla de Inicio");
    }
  }
}
</script>
