<template>
  <v-container>
    <v-icon
        :color=this.color
        :size=this.size
    >mdi-water</v-icon>
    {{this.color}}
  </v-container>
</template>

<script>
export default {
  name: "dropBasic",
  props: {
    color:null,
    size:null
  }
}
</script>

<style scoped>
</style>