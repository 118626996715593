<template>
  <v-container>
    <p v-if="this.fecha!=null">Última fecha de actualización: {{this.fecha}}</p>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
      <v-select
          placeholder="Selecciona un estado"
          v-model="estado"
          :items="this.estados"
          @change="changeEstado">

      </v-select>
      <dropPercentage_Display
          :preys="this.presas"></dropPercentage_Display>
    </v-form>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import dropPercentage_Display from "@/components/dropPercentage_Display";
export default {
  name: "tengoAgua_View",
  components:{
    dropPercentage_Display
  },
  data(){
    return{
      estado:'Aguascalientes',
      valid: true,
      estados:[],
      presas:[],
      fecha:null
    }
  },
  mounted(){
    this.getEstados()
    this.changeEstado()
  },
  methods:{
    getEstados(){
      firebase.firestore().collection('estados').orderBy("nombre").get()
      .then((querySnapshot)=>{
        querySnapshot.forEach((estadoDoc)=>{
          this.estados.push(estadoDoc.data().nombre)
        })
      })
    },
    changeEstado(){
      firebase.analytics().logEvent("Estado: "+this.estado);
      this.presas=[]
      let cont=1
      firebase.firestore().collection('presasMexico').where("estado","==",this.estado).get()
          .then((querySnapshot)=>{
            querySnapshot.forEach((presaDoc)=>{
              let presaAux = presaDoc.data()
              this.fecha = presaAux.fecha
              presaAux.porcentaje = parseInt((parseFloat(presaAux.valorActual) / parseFloat(presaAux.capacidad)) * 100)
              if(isNaN(presaAux.porcentaje)){
                presaAux.porcentaje=0
              }
              presaAux.dropForm = 'dropForm'+cont
              cont++
              this.presas.push(presaAux)
            })
          })
    }
  }
}
</script>

<style scoped>

</style>