import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyApoD6h1DL9mYTfqk4KdKVZJMJrv5jRuug",
    authDomain: "aguamexico-66d2b.firebaseapp.com",
    projectId: "aguamexico-66d2b",
    storageBucket: "aguamexico-66d2b.appspot.com",
    messagingSenderId: "1048527372700",
    appId: "1:1048527372700:web:b602c9eb6d4439747e4859",
    measurementId: "G-WHQY25S9ES"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore;