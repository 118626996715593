<template>
  <v-container class="container--fluid">
    <v-row v-if="this.lenPresas==0">
      <v-col cols="12">
        <p>Lo sentimos, no hay presas registradas en este estado</p>
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col
          class="mt-10"
          sm="6"
          md="4"
          lg="3"
          cols="12"
          justify="center"
          v-for="(element,index) in this.preys"
          :key="index">
        <v-row justify="center">
          <p class="text-h6 font-weight-bold">{{element.nombre}}</p>
        </v-row>
        <v-row justify="center">
          <div class="dropForm" :id=element.dropForm>
            <dropBasic
                v-if="element.porcentaje<30"
                class="drop"
                color="red"
                size="150"></dropBasic>
            <dropBasic
                v-else-if="element.porcentaje<60"
                class="drop"
                color="yellow"
                size="150"></dropBasic>
            <dropBasic
                v-else
                class="drop"
                color="green"
                size="150"></dropBasic>
          </div>
        </v-row>
        <v-row justify="center">
          <p class="font-weight-bold">{{element.porcentaje}}%</p>
        </v-row>
        <v-row justify="center">
          <p>Capacidad: {{ element.capacidad}} h/m3</p>
        </v-row>
        <v-row justify="center">
          <p>Nivel actual: {{ element.valorActual}} h/m3</p>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import dropBasic from "@/components/dropBasic";

export default {
  name: "dropPercentage_Display",
  components: {
    dropBasic
  },
  props:{
    preys:[]
  },
  data(){
    return{
      dropSize: 150,
      lenPresas:0,
    }
  },
  updated() {
    this.configureDrops()
  },
  methods:{
    configureDrops(){
      this.lenPresas=this.preys.length
      let cont = 1
      this.preys.forEach(element=>{
        this.configureDrop(cont,element.porcentaje)
        cont++
      })
    },
    configureDrop(id,percentage){
      let element = document.getElementById("dropForm"+id)
      let scale = 107
      let scalePercentage = ((70*percentage)/100)+26
      percentage = scale-((scalePercentage/scale)*100)
      let polStr = "polygon(75% "+(percentage+2)+"%,70% "+(percentage)+"%,65% "+(percentage+2)+"%,60% "+(percentage)+"%,55% "+(percentage+2)+"%,50% "+(percentage)+"%,45% "+(percentage+2)+"%,40% "+(percentage)+"%,35% "+(percentage+2)+"%,30% "+(percentage)+"%,25% "+(percentage+2)+"%, 0% 100%, 100% 100%)"
      element.style.clipPath = polStr
    }
  }
}
</script>

<style scoped>
.dropForm{
  height: 150px;
  width: 150px;
}
</style>