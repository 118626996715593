import Vue from 'vue'
import VueRouter from 'vue-router'
import tengoAgua_View from "@/views/tengoAgua_View";
import Home from "@/views/Home";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Home
  },
  {
    path: '/tengoAgua',
    name: 'tengoAgua',
    component: tengoAgua_View
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
